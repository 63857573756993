<template>
  <v-card>
    <v-card-title>
      <div v-if="!scenarioHasData" class="text-h4">{{ $t('createScenario') }}</div>
      <div v-else-if="scenarioHasData" class="text-h4">{{ $t('editScenario') }}</div>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="formValid">
        <v-text-field
          v-model="currentScenario.name"
          :rules="nameRules"
          :label="$t('name')"
          :hint="$t('scenarioNameHelperText')"
          persistent-hint
          autocomplete="off"
          counter="50"
        />
        <v-textarea
          v-model="currentScenario.description"
          class="mt-8"
          :rules="descriptionRules"
          :label="$t('description')"
          :hint="$t('scenarioDescriptionHelperText')"
          persistent-hint
          autocomplete="off"
          auto-grow
          rows="2"
          counter="255"
        />

        <!-- <div v-if="scenarioHasData" class="pt-6">
          <div>{{ $t('attachments') }} ({{ editedAttachments.length }})</div>
          <attachment-grid
            :attachments="editedAttachments"
            editable
            preview
            class="rounded gray200"
            @add="addAttachmentToAdditions"
            @remove="addAttachmentToRemovals"
          />
        </div> -->

        <v-switch
          v-model="currentScenario.includePosition"
          inset
          :label="$t('includePosition')"
          hide-details="true"
          class="ml-1"
        />
        <div class="mt-2 caption">
          <label v-if="currentScenario.includePosition">{{
            $t('includePositionDescription')
          }}</label>
          <label v-else>{{ $t('doNotIncludePositionDescription') }}</label>
        </div>
      </v-form>

      <div class="d-flex pt-6 justify-center">
        <v-chip large :disabled="!formValid" @click="editAlert">
          <v-img :src="alertTypeImage[currentScenario.alertType]" max-width="40px" class="ml-n2" />
          <div class="px-5">
            {{ $t('editAlert') }}
          </div>
          <v-icon class="ml-2">fal fa-edit</v-icon>
        </v-chip>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn text class="mr-1" :disabled="loading" @click="cancel">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        :disabled="!formValid || alertInvalid"
        :loading="loading"
        color="primary"
        @click="save"
      >
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="editAlertDialog" fullscreen>
      <new-alert
        :data="alertData"
        :severity="alertType"
        :scenario="scenarioData"
        fullscreen
        @cancel="cancelEditAlert"
        @save="saveAlert"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import NewAlert from '@/views/newAlert/NewAlert';
import fileInput from '@/util/fileInput';
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';

export default {
  name: 'EditScenario',

  components: {
    NewAlert,
  },

  props: {
    scenario: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      required: false,
    },
  },

  data: (self) => ({
    editAlertDialog: null,

    formValid: false,

    alertTypeImage: {
      1: require('@/assets/images/alarm.svg'),
      2: require('@/assets/images/urgent-alert.svg'),
    },

    nameRules: [
      (name) =>
        name?.length >= 3 ||
        self.$t('mustBeMinimum', null, {
          value: self.$t('name'),
          characters: 3,
        }),
      (name) =>
        name?.length <= 50 ||
        self.$t('mustBeMaximum', null, {
          value: self.$t('name'),
          characters: 50,
        }),
    ],

    descriptionRules: [
      (description) =>
        !description ||
        description.length <= 255 ||
        self.$t('mustBeMaximum', null, {
          value: self.$t('description'),
          characters: 255,
        }),
    ],

    currentScenario: {
      name: '',
      description: '',
      alertType: 2,
      alertData: {},
      sortIndex: {},
      includePosition: false,
    },

    attachments: [],
    filesToAdd: [],
    filesToRemove: [],
  }),

  computed: {
    alertType() {
      return this.alertTypeStringValue(this.currentScenario?.alertType);
    },

    scenarioHasData() {
      return this.scenario.name?.length > 0;
    },

    alertData() {
      return this.currentScenario.alertData;
    },
    scenarioData() {
      return this.currentScenario;
    },

    alertInvalid() {
      return Object.keys(this.alertData).length < 4;
    },

    editedAttachments() {
      return [
        ...this.filesToAdd,
        ...this.attachments.filter((a) => this.filesToRemove.indexOf(a) < 0),
      ];
    },
  },

  watch: {
    scenario() {
      this.reset();
    },
  },

  mounted() {
    this.reset();
  },

  methods: {
    reset() {
      this.$refs.form.reset();
      this.currentScenario.id = this.scenario.id;
      this.currentScenario.name = this.scenario.name ?? '';
      this.currentScenario.description = this.scenario.description ?? '';
      this.currentScenario.alertType = this.scenario.alertType ?? 2;
      this.currentScenario.alertData = this.scenario.alertData ?? {};
      this.currentScenario.sortIndex = this.scenario.sortIndex;
      this.currentScenario.includePosition = this.scenario.includePosition ?? false;

      if (this.scenario.attachments) {
        this.currentScenario.attachments = this.scenario.attachments;
      }
      this.filesToAdd = [];
      this.filesToRemove = [];
    },

    alertTypeStringValue(alertType) {
      return (
        {
          1: 'alarm',
          2: 'urgentAlert',
        }[alertType] ?? 'urgentAlert'
      );
    },

    alertTypeEnumValue(alertType) {
      return (
        {
          alarm: 1,
          urgentAlert: 2,
        }[alertType] ?? 2
      );
    },

    editAlert() {
      this.saveScenarioDraft(this.currentScenario);
      this.editAlertDialog = true;
    },

    cancelEditAlert() {
      this.editAlertDialog = false;
    },

    saveAlert(alertType, alertData) {
      this.currentScenario.alertType = this.alertTypeEnumValue(alertType);
      this.currentScenario.alertData = alertData;
      this.currentScenario.attachments = alertData.attachments;
      this.editAlertDialog = false;
      this.$emit('savedAlert', alertData);
    },

    async saveScenarioDraft(scenario) {
      if (!scenario.id) {
        try {
          let createdScenario = await api.createScenario(scenario);
          this.currentScenario = createdScenario;
        } catch (error) {
          errorMessage(this.$t('saveScenarioError'), this.$t('saveScenarioErrorMsg'), error);
        }
      }
    },

    async addAttachmentToAdditions() {
      const files = await fileInput();

      if (!files?.length) {
        return;
      }

      // FileList does not support forEach, so we NEED to use a for-loop
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fr = new FileReader();

        fr.addEventListener(
          'load',
          () => {
            const presignedUrl = fr.result;
            this.filesToAdd.push({
              name: file.name,
              presignedUrl,
              file: file,
              size: file.size,
            });
          },
          false,
        );

        fr.readAsDataURL(file);
      }
    },

    addAttachmentToRemovals(attachment) {
      if (attachment.file) {
        this.filesToAdd = this.filesToAdd.filter((a) => a !== attachment);
      } else {
        this.filesToRemove.push(attachment);
      }
    },

    cancel() {
      this.$emit('cancel');
    },

    save() {
      this.$emit('save', this.currentScenario);
    },
  },
};
</script>
