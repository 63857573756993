<template>
  <v-container>
    <h1 class="text-h3">{{ $t('iotDevices') }}</h1>
    <p class="text-subtitle-1">{{ $t('iotDevicesDescription') }}</p>

    <v-btn color="primary" @click="addDevice()"
      ><v-icon left>fa-plus</v-icon>{{ $t('addIotDevice') }}</v-btn
    >

    <div class="d-flex flex-wrap mx-n4">
      <iot-device
        v-for="(device, index) in computedDevices"
        :key="index"
        class="ma-4"
        :device="device"
        :event-hub="eventHub"
        @edit="editDevice"
        @remove="deleteDevice"
      />
    </div>

    <v-dialog v-model="addDeviceDialog" eager max-width="500px" persistent>
      <add-iot-device
        ref="addIotDevice"
        :newly-added-device="newlyAddedDevice"
        :scenarios="scenarios"
        @close="cancelAddDevice"
      />
    </v-dialog>

    <v-dialog v-model="editDeviceDialog" max-width="500px">
      <edit-iot-device
        :device="currentDevice"
        :scenarios="scenarios"
        @cancel="cancelEditDevice"
        @save="saveDevice"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue';
import IotDevice from '@/views/iotDevices/IotDevice';
import EditIotDevice from '@/views/iotDevices/EditIotDevice';
import AddIotDevice from '@/views/iotDevices/AddIotDevice';
import getUserProfile from '@/util/getUserProfile';
import api from '@/util/api';
import confirmDialog from '@/components/dialog/confirmDialog';
import htmlMessageDialog from '@/components/dialog/htmlMessageDialog';
import router from '@/router';

export default {
  name: 'IotDevices',

  components: {
    IotDevice,
    EditIotDevice,
    AddIotDevice,
  },

  data: () => ({
    devices: [],
    scenarios: [],

    addDeviceDialog: null,
    editDeviceDialog: null,

    newlyAddedDevice: null,
    currentDevice: {},

    eventHub: new Vue(),
    events: [],
  }),

  computed: {
    computedDevices() {
      return this.devices.map((device) => {
        const actions = device.actions;
        actions.forEach((action) => {
          action.scenario = this.scenarios.find((scenario) => scenario.id === action.scenarioId);
        });
        return {
          ...device,
          actions,
        };
      });
    },
  },

  watch: {
    addDeviceDialog() {
      if (!this.addDeviceDialog) {
        this.$refs.addIotDevice.reset();
        this.newlyAddedDevice = null;
      }
    },
  },

  beforeDestroy() {
    this.events.forEach((event) => this.$signalR.off(event));
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async subscribeToSignalREvents(customerId) {
      const newDeviceEvent = `newIotDeviceCustomerId:${customerId}`;
      this.$signalR.on(newDeviceEvent, (data) => {
        this.loadDevice(data.iotDeviceId);
      });

      const triggeredDeviceEvent = `iotDeviceTriggeredCustomerId:${customerId}`;
      this.$signalR.on(triggeredDeviceEvent, (data) => {
        this.triggerDevice(data.iotDeviceId);
      });

      const loadDevicesEvents = [
        newDeviceEvent,
        `updatedIotDeviceCustomerId:${customerId}`,
        `deletedIotDeviceCustomerId:${customerId}`,
      ];
      loadDevicesEvents.forEach((event) =>
        this.$signalR.on(event, () => {
          this.loadDevices();
        }),
      );

      this.events = [newDeviceEvent, triggeredDeviceEvent, ...loadDevicesEvents];
    },

    async loadData() {
      const profile = await getUserProfile();
      if (!profile.features.find((feature) => feature.featureName === 'flicHub')) {
        htmlMessageDialog(
          this.$t('featureUnavailable'),
          this.$t('featureUnavailableDescription', null, {
            mail: 'sales@teamalert.net',
            featureName: this.$t('flicHub'),
          }),
          this.$t('ok'),
        )
          .open()
          .then(() => {
            router.replace('/');
          });
      } else {
        const customerId = profile.customer.id;
        this.subscribeToSignalREvents(customerId);
        this.loadDevices();
        this.loadScenarios();
      }
    },

    async loadScenarios() {
      const res = await api.getAllScenarios();
      this.scenarios = res.scenarios;
    },

    async loadDevices() {
      const res = await api.getIotDevices();
      this.devices = res.devices;
    },

    async loadDevice(id) {
      this.newlyAddedDevice = await api.getIotDevice(id);
    },

    triggerDevice(id) {
      this.eventHub.$emit('triggered', id);
    },

    addDevice() {
      this.addDeviceDialog = true;
    },

    cancelAddDevice() {
      this.addDeviceDialog = false;
    },

    editDevice(device) {
      this.currentDevice = device;
      this.editDeviceDialog = true;
    },

    async deleteDevice(device) {
      const confirmed = await confirmDialog(
        this.$t('deleteIotDevice'),
        this.$t('deleteIotDeviceConfirm', null, {
          deviceName: device.name,
        }),
        this.$t('cancel'),
        this.$t('delete'),
      ).open();

      if (confirmed) {
        api.deleteIotDevice(device.id).then(() => {
          const index = this.devices.findIndex((i) => i.id === device.id);
          this.devices.splice(index, 1);
          this.devices = [...this.devices];
        });
      }
    },

    cancelEditDevice() {
      this.currentDevice = {};
      this.editDeviceDialog = false;
    },

    saveDevice(device) {
      api.updateIotDevice(device);
      this.editDeviceDialog = false;
      this.addDeviceDialog = false;
    },
  },
};
</script>
