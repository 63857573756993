<template>
  <div>
    <v-autocomplete
      v-model="selectedScenario"
      :label="label"
      :items="computedScenarios"
      item-text="name"
      item-value="id"
      :return-object="returnObject"
      :rules="rules"
      chips
      :search-input.sync="scenarioSearch"
      :prepend-icon="prependIcon"
      :messages="messages"
      :hint="hint"
      :persistent-hint="persistentHint"
      :error-messages="errorMessages"
      :disabled="disabled"
      @change="onChange"
    >
      <template #selection="data">
        <v-chip pill>
          <v-img :src="alertTypeImage[data.item.alertType]" max-width="30px" class="ml-n2 mr-1" />
          {{ data.item.name }}
        </v-chip>
      </template>

      <template #prepend-item>
        <v-list-item @click="addScenarioDialog = true">
          <v-list-item-avatar>
            <v-icon color="primary" class="pa-2">fa-plus</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t('createScenario') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template #item="{ item }">
        <v-list-item-avatar>
          <v-img :src="alertTypeImage[item.alertType]" max-width="30px" class="mr-1" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-chip>
              <v-icon left class="pa-1">fa-fw fa-{{ item.recipients.icon }}</v-icon>
              {{ item.recipients.text }}
            </v-chip>
            <v-chip v-if="item.areaLimits.show" class="ml-1">
              <v-icon left class="pa-1">fa-fw fa-{{ item.areaLimits.icon }}</v-icon>
              {{ item.areaLimits.text }}
            </v-chip>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="showIds">ID: {{ item.id }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <template v-if="clearable && selectedScenario" #append>
        <v-btn
          icon
          @click.stop="
            selectedScenario = null;
            onChange();
          "
          ><v-icon class="pa-1">fa-times</v-icon></v-btn
        >
      </template>
    </v-autocomplete>

    <v-dialog v-model="addScenarioDialog" max-width="500px">
      <edit-scenario @cancel="cancelEditScenario" @save="saveScenario" />
    </v-dialog>
  </div>
</template>

<script>
import api from '@/util/api';
import EditScenario from './EditScenario.vue';

export default {
  name: 'ScenarioSelector',

  components: {
    EditScenario,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },

    scenarios: {
      type: Array,
      required: false,
      default: () => [],
    },

    prependIcon: {
      type: String,
      required: false,
      default: null,
    },

    showIds: {
      type: Boolean,
      required: false,
      default: false,
    },

    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },

    rules: {
      type: Array,
      required: false,
      default: () => [],
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    messages: {
      type: [String, Array],
      required: false,
      default: () => [],
    },

    hint: {
      type: String,
      required: false,
      default: undefined,
    },

    persistentHint: {
      type: Boolean,
      required: false,
      default: false,
    },

    errorMessages: {
      type: [String, Array],
      required: false,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    scenarioSearch: '',
    selectedScenario: null,
    addScenarioDialog: null,
    alertTypeImage: {
      1: require('@/assets/images/alarm.svg'),
      2: require('@/assets/images/urgent-alert.svg'),
    },
  }),

  computed: {
    computedScenarios() {
      return this.scenarios.map((scenario) => ({
        ...scenario,
        recipients: this.recipients(scenario),
        areaLimits: this.areaLimits(scenario),
        disabled: !scenario.hasValidTarget,
        // ^ disables the scenario in autocomplete if it has 0 valid targets/recipients.
      }));
    },
  },

  watch: {
    value() {
      this.selectedScenario = this.value;
    },
  },

  created() {
    this.selectedScenario = this.value;
  },

  methods: {
    cancelEditScenario() {
      this.addScenarioDialog = false;
    },

    async saveScenario(scenario) {
      let highestSortIndex = 0;
      this.scenarios.forEach((s) => {
        if (s.sortIndex > highestSortIndex) {
          highestSortIndex = s.sortIndex;
        }
      });
      scenario.sortIndex = highestSortIndex + 1;
      await api.updateScenario(scenario);
      // eslint-disable-next-line vue/no-mutating-props
      this.scenarios.push(scenario);
      this.$emit('input', scenario);
      this.addScenarioDialog = false;
    },

    onChange() {
      this.scenarioSearch = '';
      this.$emit('input', this.selectedScenario);
    },

    recipients(scenario) {
      const { company, teams, users, devices } = scenario.alertData.target;

      let text;
      let icon;

      if (company) {
        text = this.$t('company');
        icon = 'building';
      } else if (teams.length > 0) {
        text = `${teams.length} ${(teams.length === 1
          ? this.$t('team')
          : this.$t('teams')
        ).toLowerCase()}`;
        icon = 'users';
      } else if (users.length > 0) {
        text = `${users.length} ${(users.length === 1
          ? this.$t('member')
          : this.$t('members')
        ).toLowerCase()}`;
        icon = 'user';
      } else if (devices.length > 0) {
        text = `${devices.length} ${(devices.length === 1
          ? this.$t('device')
          : this.$t('devices')
        ).toLowerCase()}`;
        icon = 'user';
      } else {
        text = 'Unknown target';
        icon = 'question';
      }

      return {
        text,
        icon,
      };
    },

    areaLimits(scenario) {
      const { locations, adHocLocation } = scenario.alertData.target;

      let text;
      let icon;
      let show = true;

      if (locations.length > 0) {
        text = `${locations.length} ${(locations.length === 1
          ? this.$t('locationPredefined')
          : this.$t('locationsPredefined')
        ).toLowerCase()}`;
        icon = 'map-pin';
      } else if (adHocLocation?.length > 0) {
        text = this.$t('adHocLocation');
        icon = 'draw-polygon';
      } else {
        text = 'No location';
        icon = 'map-pin';
        show = false;
      }

      return {
        text,
        icon,
        show,
      };
    },
  },
};
</script>
