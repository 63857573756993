<template>
  <v-card
    dark
    color="gray800"
    width="100%"
    max-width="400px"
    class="iot-device"
    rounded="xl"
    :class="{ triggered }"
  >
    <v-card-title>
      <v-col cols="12" class="d-flex align-center pa-0 mt-n3">
        <div class="mr-2 text-body-2 d-flex align-center">
          <v-icon class="pa-1" :color="batteryLevel.color"
            >fa-fw fa-battery-{{ batteryLevel.icon }}</v-icon
          >
          {{ device.batteryLevel }}%
        </div>

        <div class="text-body-2 d-flex align-center">
          <v-icon class="pa-1">fa-fw fa-microchip</v-icon>
          {{ type }}
        </div>

        <v-spacer />

        <v-btn fab icon class="mr-1" @click="edit">
          <v-icon>fa-edit</v-icon>
        </v-btn>

        <v-btn fab icon class="mr-n3" @click="remove">
          <v-icon color="redPrimary">fa-trash</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="pa-0">
        {{ device.name }}
      </v-col>
    </v-card-title>
    <v-card-text class="d-flex flex-column align-start">
      <template v-for="(action, index) in device.actions">
        <v-chip v-if="action.scenario" :key="index" class="my-1" to="/automation/scenarios">
          <v-img
            :src="alertTypeImage[action.scenario.alertType]"
            max-width="30px"
            class="ml-n3 mr-1"
          />
          '{{ action.action }}': {{ action.scenario.name }}
        </v-chip>
      </template>
      <template v-if="device.actions.length < 1">
        <v-chip class="my-1">
          <v-icon left class="pa-1">fa-question-circle</v-icon>
          {{ $t('noActions') }}
        </v-chip>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'IotDevice',

  props: {
    device: {
      type: Object,
      required: true,
    },

    eventHub: {
      type: Vue,
      required: true,
    },
  },

  data: () => ({
    triggered: false,
    alertTypeImage: {
      1: require('@/assets/images/alarm.svg'),
      2: require('@/assets/images/urgent-alert.svg'),
    },
  }),

  computed: {
    batteryLevel() {
      const batteryLevel = this.device.batteryLevel;
      if (batteryLevel > 90) {
        return {
          icon: 'full',
          color: 'green',
        };
      } else if (batteryLevel > 65) {
        return {
          icon: 'three-quarters',
          color: 'green',
        };
      } else if (batteryLevel > 40) {
        return {
          icon: 'half',
          color: 'orange',
        };
      } else if (batteryLevel > 10) {
        return {
          icon: 'quarter',
          color: 'red',
        };
      } else {
        return {
          icon: 'slash',
          color: 'red',
        };
      }
    },

    type() {
      return (
        {
          0: this.$t('flic'),
        }[this.device.type] ?? this.$t('unknown')
      );
    },
  },

  mounted() {
    this.eventHub.$on('triggered', (id) => {
      if (this.device.id === id) {
        this.trigger();
      }
    });
  },

  methods: {
    trigger() {
      this.triggered = true;
      setTimeout(() => {
        this.triggered = false;
      }, 500);
    },

    remove() {
      this.$emit('remove', this.device);
    },

    edit() {
      this.$emit('edit', this.device);
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes trigger {
  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.iot-device {
  &.triggered {
    animation-duration: 0.5s;
    animation-name: trigger;
  }
}
</style>
