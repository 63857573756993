<template>
  <v-card>
    <v-card-title>{{ $t('edit') }} "{{ device.name }}"</v-card-title>

    <v-card-text>
      <v-form v-model="formValid">
        <div>
          <v-row
            v-for="(action, index) in currentDevice.actions"
            :key="index"
            class="d-flex align-end"
          >
            <v-col cols="5">
              <v-select
                v-model="action.action"
                :items="actionOptions"
                :label="$t('action')"
                :rules="actionRules"
              />
            </v-col>
            <v-col cols="5">
              <scenario-selector
                v-model="action.scenario"
                :label="$t('scenario')"
                :scenarios="scenarios"
                :rules="scenarioRules"
                return-object
              />
            </v-col>
            <v-col cols="2" class="align-self-center d-flex justify-center">
              <v-btn icon @click="removeAction(action)">
                <v-icon class="pa-1">fa-trash</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex align-end">
            <v-col cols="5">
              <v-text-field disabled />
            </v-col>
            <v-col cols="5">
              <v-text-field disabled />
            </v-col>
            <v-col cols="2" class="align-self-center d-flex justify-center">
              <v-btn icon @click="addAction">
                <v-icon class="pa-1">fa-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn text class="mr-1" @click="cancel">{{ $t('cancel') }}</v-btn>
      <v-btn :disabled="!formValid" color="primary" @click="save">{{ $t('save') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { v4 as uuid } from 'uuid';
import ScenarioSelector from '@/components/ScenarioSelector';
import is from '@/lib/util/is';

export default {
  name: 'EditIotDevice',

  components: {
    ScenarioSelector,
  },

  props: {
    device: {
      type: Object,
      required: true,
    },

    scenarios: {
      type: Array,
      required: true,
    },
  },

  data: (self) => ({
    formValid: false,

    currentDevice: {},

    actionRules: [
      (action) =>
        (action != undefined && action != null) ||
        self.$t('isRequired', null, {
          value: self.$t('action'),
        }),
    ],

    scenarioRules: [
      (scenario) =>
        scenario != null ||
        self.$t('isRequired', null, {
          value: self.$t('scenario'),
        }),
    ],

    actionOptions: ['click', 'hold', 'doubleclick'],
  }),

  watch: {
    device() {
      this.reset();
    },
  },

  mounted() {
    this.reset();
  },

  methods: {
    reset() {
      this.currentDevice = {
        ...this.device,
        actions: this.device?.actions?.map((action) => ({
          ...action,
          uuid: uuid(),
        })),
      };
      const actions = this.currentDevice?.actions;
      if (is.array(actions) && actions.length < 1) {
        this.addAction();
      }
    },

    addAction() {
      this.currentDevice.actions.push({
        uuid: uuid(),
        action: 'click',
        scenario: null,
      });
    },

    removeAction(actionToRemove) {
      this.currentDevice.actions = this.currentDevice.actions.filter(
        (action) => action.uuid !== actionToRemove.uuid,
      );
    },

    cancel() {
      this.$emit('cancel');
    },

    save() {
      this.currentDevice.actions.forEach((action) => {
        action.scenarioId = action.scenario.id;
      });
      this.$emit('save', this.currentDevice);
    },
  },
};
</script>
