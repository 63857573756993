<template>
  <v-card>
    <v-stepper v-model="step" class="elevation-0">
      <v-stepper-header>
        <v-stepper-step step="1" editable>{{ $t('download') }}</v-stepper-step>

        <v-divider />

        <v-stepper-step step="2" editable>{{ $t('connect') }}</v-stepper-step>

        <v-divider />

        <v-stepper-step step="3" editable>{{ $t('setup') }}</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items style="height: 400px">
        <v-stepper-content step="1">
          {{ $t('flicDownloadAppDescription') }}
          <div class="d-flex justify-center">
            <canvas ref="qrCodeCanvas" />
          </div>
          <div class="text-center text-h6">{{ flicHubUrl }}</div>
        </v-stepper-content>

        <v-stepper-content step="2">
          {{ $t('flicConnectToHubDescription') }}
          <v-img height="300px" contain src="@/assets/images/flic/flichub.png" />
        </v-stepper-content>

        <v-stepper-content step="3">
          {{ $t('flicSetupWithTeamAlertGenerate') }}
          <v-row class="d-flex align-center">
            <v-col cols="6">
              <v-btn color="primary" @click="generateCode">{{ $t('generateCode') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <div class="text-h6 text-center gray800--text font-weight-black">{{ code }}</div>
            </v-col>
          </v-row>

          <v-carousel
            v-if="code"
            v-model="carousel"
            height="300px"
            hide-delimiter-background
            light
            delimiter-icon="fa-minus"
            show-arrows-on-hover
          >
            <v-carousel-item>
              <v-img
                src="@/assets/images/flic/flic-internet-request-1.png"
                contain
                height="200px"
              />
              <p v-html="$t('iotSetupInternetRequest')" />
            </v-carousel-item>

            <v-carousel-item>
              <v-img
                src="@/assets/images/flic/flic-internet-request-2.png"
                contain
                height="200px"
              />
              <p v-html="$t('iotSetupMethodUrl', null, { taIotEventUrl: taIotEventUrl })" />
            </v-carousel-item>

            <v-carousel-item>
              <v-img
                src="@/assets/images/flic/flic-internet-request-3.png"
                contain
                height="200px"
              />
              <p v-html="$t('iotSetupHeader')" />
            </v-carousel-item>

            <v-carousel-item>
              <v-img
                src="@/assets/images/flic/flic-internet-request-4.png"
                contain
                height="200px"
              />
              <p v-html="$t('iotSetupCode', null, { code: code })" />
            </v-carousel-item>

            <v-carousel-item>
              <v-img
                src="@/assets/images/flic/flic-internet-request-5.png"
                contain
                height="200px"
              />
              {{ $t('iotSetupDefaults') }}
            </v-carousel-item>

            <v-carousel-item>
              {{ $t('iotSetupFinalStep') }}
              <v-fade-transition>
                <v-sheet color="transparent" height="200px" tile>
                  <v-row
                    v-if="newlyAddedDevice"
                    class="fill-height"
                    align="center"
                    justify="center"
                  >
                    <div>
                      <div class="text-center">
                        {{
                          $t('deviceAdded', null, {
                            name: newlyAddedDevice.name,
                          })
                        }}
                      </div>
                      <div class="d-flex justify-center">
                        <v-chip class="my-1">
                          <v-icon left class="pa-1" :color="batteryLevel.color"
                            >fa-fw fa-battery-{{ batteryLevel.icon }}</v-icon
                          >
                          {{ newlyAddedDevice.batteryLevel }}%
                        </v-chip>
                      </div>
                    </div>
                  </v-row>
                </v-sheet>
              </v-fade-transition>
            </v-carousel-item>
          </v-carousel>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-card-actions>
      <v-btn v-if="step > 1" text @click="step--">{{ $t('back') }}</v-btn>
      <v-btn v-else text @click="close">{{ $t('cancel') }}</v-btn>
      <v-spacer />
      <v-btn v-if="step < 3" color="primary" @click="step++">{{ $t('next') }}</v-btn>
      <v-btn v-else-if="code" color="primary" @click="close">{{ $t('close') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from '@/util/api';
import QRCode from 'qrcode';
import { blueDark } from '@/lib/ui/colors';
import appSettings from '@/appSettings';

export default {
  name: 'AddIotDevice',

  props: {
    newlyAddedDevice: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: () => ({
    step: 1,
    carousel: 0,
    code: '',

    flicHubUrl: 'https://start.flic.io/',
    taIotEventUrl: `${appSettings.teamAlertApi}/api/iotEvents`,
  }),

  computed: {
    deviceAdded() {
      return this.newlyAddedDevice?.id?.length > 0;
    },

    batteryLevel() {
      const batteryLevel = this.newlyAddedDevice?.batteryLevel ?? 0;
      if (batteryLevel > 90) {
        return {
          icon: 'full',
          color: 'green',
        };
      } else if (batteryLevel > 65) {
        return {
          icon: 'three-quarters',
          color: 'green',
        };
      } else if (batteryLevel > 40) {
        return {
          icon: 'half',
          color: 'orange',
        };
      } else if (batteryLevel > 10) {
        return {
          icon: 'quarter',
          color: 'red',
        };
      } else {
        return {
          icon: 'slash',
          color: 'red',
        };
      }
    },
  },

  mounted() {
    QRCode.toCanvas(this.$refs.qrCodeCanvas, this.flicHubUrl, {
      width: 200,
      color: {
        dark: blueDark,
      },
    });
  },

  methods: {
    async generateCode() {
      const res = await api.generateIotDeviceCode();
      this.code = res.code;
    },

    reset() {
      this.code = '';
      this.step = 1;
      this.carousel = 0;
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
